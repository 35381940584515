.table-component {
  border: none;
  border-radius: 3px;
}
.table-component table {
  border-spacing: 0px 4px;
  color: #eee;
  font-size: 1.6rem;
}
.table-component table td:first-child,
.table-component table th:first-child {
  border-radius: 3px 0 0 3px;
}
.table-component table thead {
  color: #717171;
  cursor: pointer;
  font-size: 1.4rem;
  text-align: center;
}
.table-component table thead img {
  height: 12px;
}
.table-component table thead th {
  padding: 10px;
  text-align: center;
}
.table-component table tbody {
  height: 400px;
  overflow-y: auto;
}
.table-component table tbody td {
  color: #717171;
  padding: 5px;
  text-align: center;
}
.table-component table tbody td:nth-child(3n) {
  color: #35cebe;
}
.table-component table tbody td:nth-child(3n + 2) {
  color: #00ec00;
}
.table-component table tbody td.true {
  background-image: url("png/tick.png");
  background-repeat: no-repeat;
  background-position: 45% 50%;
}
.table-component table tbody td button {
  background-color: inherit;
  border: none;
  border-radius: 50%;
  padding: 5px 10px;
}
.table-component table tbody td button:hover > em {
  color: rgba(255,0,0,0.8);
}
.table-component table tbody td button em {
  color: #888;
  font-size: 24px;
}
.table-component table .table-row-component {
  border: none;
  position: relative;
}
.table-component table .table-row-component td:first-child:hover > .table-tooltip-component {
  display: block;
}
.table-component table .table-tooltip-component {
  background-color: #efefef;
  border: 1px solid #666;
  border-radius: 3px;
  box-shadow: 2px 2px 2px 2px #808080;
  display: none;
  font-size: 14px;
  padding: 2px;
  position: absolute;
  top: calc(50% + 11px);
}
