.sidebar-component .title-override {
  text-transform: none;
}
.sidebar-component .sidebar-button-component {
  background-color: #fff;
  border: none;
  border-radius: 0px;
  color: #888;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.8rem;
  font-weight: 300;
  margin: 0;
  padding: 10px 20px;
  text-align: left;
  width: 100%;
}
.sidebar-component .sidebar-button-component em {
  margin-right: 5px;
}
.sidebar-component .sidebar-button-component svg {
  fill: #fff;
  height: 18px;
  padding: 0px 10px 0 0;
  position: relative;
  top: 1px;
}
.sidebar-component .sidebar-button-component svg path {
  fill: #888;
}
.sidebar-component .sidebar-button-component svg:hover {
  cursor: pointer;
}
.sidebar-component .sidebar-button-component:hover {
  background-color: #f9f9f9;
  color: #35cebe;
}
.sidebar-component .sidebar-button-component:hover path {
  fill: #25beae;
}
