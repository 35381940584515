* {
  margin: 0;
  padding: 0;
}
html,
body {
  background-color: #fff;
  background-position: 0 0;
  background-repeat: repeat;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  height: 100%;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #717171;
  font-weight: normal;
  line-height: 1;
}
button {
  background: none;
  border: 2px solid #eee;
  border-radius: 3px;
  color: #eee;
  cursor: pointer;
  display: block;
  font-size: 16px;
  margin: 5px auto;
  padding: 5px 15px;
}
button:hover {
  background: #16d;
}
.splash {
  display: block;
  margin: 10px auto;
}
#app {
  height: 100%;
}
.app-component {
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-repeat: no-repeat;
}
