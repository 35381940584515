.nav-component a,
.page-title-component a {
  font-family: 'Courier New', Courier, monospace;
  font-weight: lighter !important;
}
.nav-component a img,
.page-title-component a img {
  height: 45px;
  padding: 5px 10px;
}
.nav-component .icon-component,
.page-title-component .icon-component {
  padding-top: 10px;
}
.nav-component .icon-component a,
.page-title-component .icon-component a {
  width: 40px !important;
}
.nav-component .icon-component a em,
.page-title-component .icon-component a em {
  color: #888;
  font-size: 2rem;
  pointer-events: none;
}
.nav-component .icon-component a:hover > em,
.page-title-component .icon-component a:hover > em {
  color: #35cebe !important;
}
.nav-component .svg-icon-component svg,
.page-title-component .svg-icon-component svg {
  height: 32px;
  fill: #fff;
  padding: 12px 38px 0 0;
}
.nav-component .svg-icon-component svg path,
.page-title-component .svg-icon-component svg path {
  fill: #888;
}
.nav-component .svg-icon-component svg:hover,
.page-title-component .svg-icon-component svg:hover {
  cursor: pointer;
}
.nav-component .svg-icon-component svg:hover path,
.page-title-component .svg-icon-component svg:hover path {
  fill: #25beae;
}
