.login-container-component {
  left: 50%;
  position: relative;
  top: 20%;
  transform: translate(-50%, -20%);
  width: 400px;
}
.login-logo-component {
  background-image: url("png/logo-watermark.png");
  background-position-x: 40%;
  background-repeat: no-repeat;
  background-size: auto 50px;
  height: 50px;
  margin-bottom: 20px;
}
.login-main-component {
  background-color: rgba(24,184,171,0.4);
  padding: 20px;
}
.login-title-component {
  color: #fff;
  font-size: 2rem;
  margin-bottom: 20px;
}
.login-form-component {
  margin-bottom: 10px;
}
.form-text-input-component,
.form-password-component {
  margin-bottom: 15px;
}
.form-text-input-component input[type=text],
.form-password-component input[type=text],
.form-text-input-component input[type=password],
.form-password-component input[type=password] {
  background-color: rgba(24,184,171,0.5);
  border: none !important;
  color: #222;
  padding: 10px 15px;
}
.form-buttons-component {
  padding-top: 15px;
}
.form-buttons-component a {
  color: #fff;
}
.form-buttons-component button {
  background-color: #28e8b6;
  border: none;
  border-radius: 0;
  color: #fff;
  float: right;
  font-size: 1.4rem;
  margin-top: -5px;
}
.form-buttons-component button:last-child {
  background-color: rgba(0,0,0,0.3);
  margin-right: 5px;
}
.form-buttons-component button:hover {
  background-color: #222;
}
.login-footer-component .brand-copyright,
.login-footer-component .footer-links {
  display: inline-block;
}
.login-footer-component .footer-links {
  float: right;
}
.form-error-component {
  color: #f00;
  text-align: center;
  opacity: 0;
}
.form-error-component.error {
  opacity: 1;
}
