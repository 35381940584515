.map-load-component {
  height: 90%;
}
.map-container-component {
  height: 500px;
  margin: 0 auto;
  padding: 10px;
  width: 100%;
}
.map-component {
  height: 100%;
}
