.cluster.cluster-component img {
  display: none !important;
}
.cluster.cluster-component div {
  background-color: #36927b;
  border-radius: 50%;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 12px !important;
  font-weight: 100 !important;
}
.cluster.cluster-component div:after {
  -webkit-animation: pulse 2s ease-out !important;
  -moz-animation: pulse 2s ease-out !important;
  animation: pulse 2s linear !important;
  -webkit-animation-iteration-count: infinite !important;
  -moz-animation-iteration-count: infinite !important;
  animation-iteration-count: infinite !important;
  background-color: #36927b !important;
  border-radius: 50% !important;
  content: "" !important;
  display: block !important;
  height: 0 !important;
  padding-bottom: 100% !important;
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100% !important;
  z-index: -5;
}
@-moz-keyframes pulse {
  0% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 0.5;
  }
  25% {
    transform: matrix(1.2, 0, 0, 1.2, 0, 0);
    opacity: 0.4;
  }
  50% {
    transform: matrix(1.4, 0, 0, 1.4, 0, 0);
    opacity: 0.2;
  }
  75% {
    transform: matrix(1.6, 0, 0, 1.6, 0, 0);
    opacity: 0.1;
  }
  90% {
    transform: matrix(1.8, 0, 0, 0, 1.8, 0);
    opacity: 0.05;
  }
  100% {
    transform: matrix(2, 0, 0, 0, 2, 0);
    opacity: 0.01;
  }
}
@-webkit-keyframes pulse {
  0% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 0.5;
  }
  25% {
    transform: matrix(1.2, 0, 0, 1.2, 0, 0);
    opacity: 0.4;
  }
  50% {
    transform: matrix(1.4, 0, 0, 1.4, 0, 0);
    opacity: 0.2;
  }
  75% {
    transform: matrix(1.6, 0, 0, 1.6, 0, 0);
    opacity: 0.1;
  }
  90% {
    transform: matrix(1.8, 0, 0, 0, 1.8, 0);
    opacity: 0.05;
  }
  100% {
    transform: matrix(2, 0, 0, 0, 2, 0);
    opacity: 0.01;
  }
}
@-o-keyframes pulse {
  0% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 0.5;
  }
  25% {
    transform: matrix(1.2, 0, 0, 1.2, 0, 0);
    opacity: 0.4;
  }
  50% {
    transform: matrix(1.4, 0, 0, 1.4, 0, 0);
    opacity: 0.2;
  }
  75% {
    transform: matrix(1.6, 0, 0, 1.6, 0, 0);
    opacity: 0.1;
  }
  90% {
    transform: matrix(1.8, 0, 0, 0, 1.8, 0);
    opacity: 0.05;
  }
  100% {
    transform: matrix(2, 0, 0, 0, 2, 0);
    opacity: 0.01;
  }
}
@keyframes pulse {
  0% {
    transform: matrix(1, 0, 0, 1, 0, 0);
    opacity: 0.5;
  }
  25% {
    transform: matrix(1.2, 0, 0, 1.2, 0, 0);
    opacity: 0.4;
  }
  50% {
    transform: matrix(1.4, 0, 0, 1.4, 0, 0);
    opacity: 0.2;
  }
  75% {
    transform: matrix(1.6, 0, 0, 1.6, 0, 0);
    opacity: 0.1;
  }
  90% {
    transform: matrix(1.8, 0, 0, 0, 1.8, 0);
    opacity: 0.05;
  }
  100% {
    transform: matrix(2, 0, 0, 0, 2, 0);
    opacity: 0.01;
  }
}
