.modal-component {
  background-image: url("png/hex.png");
  bottom: 0px;
  display: block;
  left: 0px;
  pointer-events: none;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 9999;
}
.modal-component .lm-container {
  background: rgba(24,184,171,0.8);
  left: 0px;
  padding: 10px 20px;
  position: absolute;
  top: 35%;
  width: 100%;
}
.modal-component .lm-container .lm-middle {
  color: #fff;
  left: 25%;
  position: relative;
  width: 50%;
}
.modal-component .lm-container .lm-middle .lm-title {
  float: left;
  font-family: 'Courier New', Courier, monospace;
  font-size: 31px;
  font-weight: 400;
  line-height: 36px;
  width: 100%;
}
.modal-component .lm-container .lm-middle .lm-content {
  float: left;
  padding: 10px 0px 0px;
  width: 100%;
}
.modal-component .lm-container .lm-middle .lm-content p {
  margin-bottom: 10px;
}
.modal-component .lm-container .lm-middle .lm-content p.node-id {
  color: #0ff;
  font-size: 22px;
}
.modal-component .lm-container .lm-middle .lm-footer {
  float: left;
  padding: 10px 0 0;
  width: 100%;
}
.modal-component .lm-container .lm-middle .lm-footer .btn.pull-right {
  background-color: #28e8b6;
  border-radius: 0;
  color: #fff;
  float: right;
  width: 70px;
}
.modal-component .lm-container .lm-middle .lm-footer .btn.pull-right:first-child {
  background-color: #eee;
  color: rgba(0,0,0,0.3);
  margin-left: 5px;
}
.modal-component .lm-container .lm-middle .lm-footer .btn.pull-right:hover {
  background-color: rgba(0,0,0,0.6);
  color: #fff;
}
.modal-component.on {
  -webkit-animation: modal-fade 1s 1;
  animation: modal-fade 1s 1;
  pointer-events: auto;
}
@-webkit-keyframes modal-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}
@-moz-keyframes modal-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}
@-webkit-keyframes modal-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}
@-o-keyframes modal-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}
@keyframes modal-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}
